import api from "@/services/api.js";
import { make } from "vuex-pathify";

const state = {
  filtersFetched: false,
  currentFilters: {
    dates: {
      min: null,
      max: null,
    },
    gender_id: [],
    college_id: [],
    degree_id: [],
    field_id: [],
    isced_group: [],
    isced_field: [],
  },
  currentSymbolization: "getClassQuantile",
  symbolizationMethods: [
    {
      text: "Kwantyle",
      value: "getClassQuantile",
      disabled: false,
    },
    {
      text: "Naturalne przedziały",
      value: "getClassJenks",
      disabled: false,
    },
    {
      text: "Równe przedziały",
      value: "getClassEqInterval",
      disabled: false,
    },
    {
      text: "Ciąg geometryczny",
      value: "getClassGeometricProgression",
      disabled: false,
    },
  ],
  steps: null,
  dataToFilter: null,
};

const mutations = {
  ...make.mutations(state),
  SET_UPDATED_DISABLE_SYMBOL(state, datas) {
    state.symbolizationMethods.find(
      (method) => method.value === datas.method
    ).disabled = datas.value;
  },
};

const actions = {
  async filter(store, payload) {
    const query = await api.post("/api/filter", payload);
    return query;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
