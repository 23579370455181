<template>
  <div>
    <div class="title-filter">Rok przyjęcia na studia</div>
    <div style="display: flex; gap: 10px">
      <v-select
        :items="yearsArray"
        dense
        class="value-filter"
        :value="currentFilters.dates.min"
        @change="
          (e) => {
            changeDate(e, 'min');
          }
        "
        outlined
        :disabled="isLoading"
      >
        <template #selection="{ item }">
          {{ item }}/{{ parseInt(item) + 1 }}
        </template>
        <template #item="{ item }">
          {{ item }}/{{ parseInt(item) + 1 }}
        </template>
      </v-select>
      <div style="display: flex; align-items: center; width: 100%">
        <hr style="width: 100%; margin: 0; padding: 0" />
      </div>
      <v-select
        :items="yearsArray"
        dense
        class="value-filter"
        :value="currentFilters.dates.max"
        @change="
          (e) => {
            changeDate(e, 'max');
          }
        "
        outlined
        :disabled="isLoading"
      >
        <template #selection="{ item }">
          {{ item }}/{{ parseInt(item) + 1 }}
        </template>
        <template #item="{ item }">
          {{ item }}/{{ parseInt(item) + 1 }}
        </template>
      </v-select>
    </div>
    <div v-for="element in filters.selects" :key="element.title">
      <v-autocomplete
        :allow-overflow="false"
        :items="element.items"
        :ref="`autocomplete-${element.id}`"
        outlined
        multiple
        return-object
        dense
        clearable
        class="value-filter"
        @input="opacityOff(element.id)"
        @click="opacityOff(element.id)"
        @focus="
          (e) => {
            onFocus(e, element.id);
          }
        "
        @blur="
          (e) => {
            blurSelect(e, element.id);
          }
        "
        @change="
          (value) => {
            onAutocompleteChange(element.column, value);
          }
        "
        :disabled="isDisabled(element) || isLoading"
        :item-text="
          (item) => {
            return item.name;
          }
        "
        :item-value="
          (item) => {
            return item.id;
          }
        "
        :item-disabled="
          (item) => {
            return element.id === 1 && item.id === 5;
          }
        "
      >
        <template #selection>
          <span></span>
        </template>
        -->
        <template #prepend-inner>
          <div>
            <div class="title-filter">{{ element.title }}</div>
            <div style="font-size: 0.7rem; opacity: 0.7; margin-top: 4px">
              Wybranych:
              <strong>{{ currentFilters[element.column].length }}</strong>
              <span v-if="!(isDisabled(element) || isLoading) && element.id !== 0">
                ; Dostępnych:
                <span :style="`font-weight: 500; ${ element.items && dataToFilter ? element.items.length !== dataToFilter[element.dataToFilterName].length ? 'color: #508731' : '' : ''}`">
                  {{ element.items ? element.items.length : 0 }}/{{ dataToFilter ? dataToFilter[element.dataToFilterName].length : 0 }}
                </span>
              </span>
            </div>
          </div>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item="{ active, item, attrs, on, parent }">
          <v-list-item
            v-on="on"
            class="custom-list-item"
            style="align-items: start !important"
            v-bind="attrs"
            #default="{ active }"
          >
            <v-list-item-action>
              <v-checkbox
                dense
                :ripple="false"
                :input-value="active"
              ></v-checkbox>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                <v-row
                  no-gutters
                  align="center"
                  style="display: block !important"
                >
                  <div v-html="parent.genFilteredText(item.name)">
                    {{ item.name }}
                  </div>
                  <div
                    v-if="element.column === 'isced_field'"
                    style="white-space: normal"
                    class="subheader-list-item caption"
                  >
                    {{ groupNameFromField(item.id) }}
                  </div>
                  <div
                    v-if="element.column === 'field_id'"
                    style="white-space: normal"
                    class="subheader-list-item caption"
                  >
                    {{ fieldNameFromStudies(item.field_id) }}
                  </div>
                  <v-alert
                    v-if="element.id === 1 && item.id === 5"
                    dense
                    text
                    :icon="false"
                    type="info"
                    transition="fade-transition"
                    style="
                      font-size: 0.8rem;
                      margin-top: 1rem;
                      white-space: normal;
                    "
                  >
                    Zgodnie z zawartym Porozumieniem, Uniwersytet Przyrodniczy w
                    Lublinie nie wyraził chęci prezentacji danych w formie
                    aplikacji, umożliwiającej wyodrębnienie danych tej Uczelni.
                    Prezentacja ogólnej analizy napływu studentów do Lublina w
                    odniesieniu do całego miasta zawiera jednak dane dotyczące
                    Uniwersytetu Przyrodniczego w Lublinie, bez możliwości
                    wyodrębnienia danych tej Uczelni.
                  </v-alert>
                </v-row>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-autocomplete>
    </div>
  </div>
</template>

<script>
import { VAutocomplete, VSelect } from "vuetify/lib";
import { get, sync } from "vuex-pathify";
export default {
  components: {
    VSelect,
    VAutocomplete,
  },
  props: {
    isLoading: {
      type: Boolean,
    },
  },
  data: () => ({
    filteredStudies: [],
    filteredGroups: [],
    filteredFields: [],
    filteredColleges: [],
    filteredDegrees: [],
  }),
  watch: {
    dataToFilter: {
      handler(nV) {
        if (nV) {
          this.filteredStudies = [...nV.studies];
          this.filteredGroups = [...nV.group];
          this.filteredFields = [...nV.field];
          this.filteredColleges = [...nV.colleges];
          this.filteredDegrees = [...nV.degrees];
        }
      },
    },
    "currentFilters.college_id": function (nV, oV) {
      if (this.dataToFilter && nV !== oV) {
        const currentDegreeIds = this.currentFilters.degree_id.map((x) => x.id);
        const currentCollegeIds = nV.map((x) => x.id);
        const currentGroupIds = this.currentFilters.isced_group.map(
          (x) => x.id
        );
        const currentFieldIds = this.currentFilters.isced_field.map(
          (x) => x.id
        );
        this.setFilteredGroups(currentCollegeIds, currentDegreeIds);
        this.setFilteredFields(
          currentGroupIds,
          currentCollegeIds,
          currentDegreeIds
        );
        this.setFilteredStudies(
          currentGroupIds,
          currentFieldIds,
          currentCollegeIds,
          currentDegreeIds
        );
        this.setFilteredDegrees();
      }
    },
    "currentFilters.degree_id": function (nV, oV) {
      if (this.dataToFilter && nV !== oV) {
        const currentDegreeIds = nV.map((x) => x.id);
        const currentCollegeIds = this.currentFilters.college_id.map(
          (x) => x.id
        );
        const currentGroupIds = this.currentFilters.isced_group.map(
          (x) => x.id
        );
        const currentFieldIds = this.currentFilters.isced_field.map(
          (x) => x.id
        );
        this.setFilteredGroups(currentCollegeIds, currentDegreeIds);
        this.setFilteredFields(
          currentGroupIds,
          currentCollegeIds,
          currentDegreeIds
        );
        this.setFilteredStudies(
          currentGroupIds,
          currentFieldIds,
          currentCollegeIds,
          currentDegreeIds
        );
        this.setFilteredColleges();
      }
    },
    "currentFilters.isced_group": function (nV, oV) {
      if (this.dataToFilter && nV !== oV) {
        const currentDegreeIds = this.currentFilters.degree_id.map((x) => x.id);
        const currentGroupIds = nV.map((x) => x.id);
        const currentFieldIds = this.currentFilters.isced_field.map(
          (x) => x.id
        );
        const currentCollegeIds = this.currentFilters.college_id.map(
          (x) => x.id
        );
        this.setFilteredFields(
          currentGroupIds,
          currentCollegeIds,
          currentDegreeIds
        );
        this.setFilteredStudies(
          currentGroupIds,
          currentFieldIds,
          currentCollegeIds,
          currentDegreeIds
        );
        this.setFilteredColleges();
        this.setFilteredDegrees();
      }
    },
    "currentFilters.isced_field": function (nV, oV) {
      if (this.dataToFilter && nV !== oV) {
        const currentDegreeIds = this.currentFilters.degree_id.map((x) => x.id);
        const currentFieldIds = nV.map((x) => x.id);
        const currentGroupIds = this.currentFilters.isced_group.map(
          (x) => x.id
        );
        const currentCollegeIds = this.currentFilters.college_id.map(
          (x) => x.id
        );
        this.setFilteredStudies(
          currentGroupIds,
          currentFieldIds,
          currentCollegeIds,
          currentDegreeIds
        );
        this.setFilteredColleges();
        this.setFilteredDegrees();
      }
    },
    "currentFilters.field_id": function (nV, oV) {
      if (this.dataToFilter && nV !== oV) {
        this.setFilteredColleges();
        this.setFilteredDegrees();
      }
    },
  },
  methods: {
    setFilteredDegrees() {
      const groupDegrees = [
        ...new Set(
          this.currentFilters.isced_group.map((x) => x.degree_ids).flat()
        ),
      ];
      const fieldDegrees = [
        ...new Set(
          this.currentFilters.isced_field.map((x) => x.degree_ids).flat()
        ),
      ];
      const studiesDegrees = [
        ...new Set(
          this.currentFilters.field_id.map((x) => x.degree_ids).flat()
        ),
      ];
      const collegesDegrees = [
        ...new Set(
          this.currentFilters.college_id.map((x) => x.degree_ids).flat()
        ),
      ];
      const filteredDegrees = this.dataToFilter.degrees.filter((degrees) => {
        let inGroup = true;
        let inField = true;
        let inStudy = true;
        let inCollege = true;
        if (groupDegrees.length > 0) {
          if (!groupDegrees.includes(degrees.id)) inGroup = false;
        }
        if (fieldDegrees.length > 0) {
          if (!fieldDegrees.includes(degrees.id)) inField = false;
        }
        if (studiesDegrees.length > 0) {
          if (!studiesDegrees.includes(degrees.id)) inStudy = false;
        }
        if (collegesDegrees.length > 0) {
          if (!collegesDegrees.includes(degrees.id)) inCollege = false;
        }
        return inField && inGroup && inStudy && inCollege;
      });
      this.filteredDegrees =
        filteredDegrees.length === 0
          ? this.dataToFilter.degrees
          : filteredDegrees;
    },
    setFilteredColleges() {
      const degreeColleges = [
        ...new Set(
          this.currentFilters.degree_id.map((x) => x.college_ids).flat()
        ),
      ];
      const groupColleges = [
        ...new Set(
          this.currentFilters.isced_group.map((x) => x.college_ids).flat()
        ),
      ];
      const fieldColleges = [
        ...new Set(
          this.currentFilters.isced_field.map((x) => x.college_ids).flat()
        ),
      ];
      const studiesColleges = [
        ...new Set(
          this.currentFilters.field_id.map((x) => x.college_ids).flat()
        ),
      ];
      const filteredColleges = this.dataToFilter.colleges.filter((college) => {
        let inGroup = true;
        let inField = true;
        let inStudy = true;
        let inDegree = true;
        if (groupColleges.length > 0) {
          if (!groupColleges.includes(college.id)) inGroup = false;
        }
        if (fieldColleges.length > 0) {
          if (!fieldColleges.includes(college.id)) inField = false;
        }
        if (studiesColleges.length > 0) {
          if (!studiesColleges.includes(college.id)) inStudy = false;
        }
        if (degreeColleges.length > 0) {
          if (!degreeColleges.includes(college.id)) inDegree = false;
        }
        return inField && inGroup && inStudy && inDegree;
      });
      this.filteredColleges =
        filteredColleges.length === 0
          ? this.dataToFilter.colleges
          : filteredColleges;
    },
    setFilteredGroups(currentCollegeIds, currentDegreeIds) {
      const filteredGroups = this.dataToFilter.group.filter((group) => {
        let inCollege = true;
        let inDegree = true;
        if (currentCollegeIds.length > 0) {
          if (!group.college_ids.some((x) => currentCollegeIds.includes(x)))
            inCollege = false;
        }
        if (currentDegreeIds.length > 0) {
          if (!group.degree_ids.some((x) => currentDegreeIds.includes(x)))
            inDegree = false;
        }
        return inCollege && inDegree;
      });
      this.filteredGroups =
        filteredGroups.length === 0 ? this.dataToFilter.group : filteredGroups;
    },
    setFilteredFields(currentGroupIds, currentCollegeIds, currentDegreeIds) {
      const filteredFields = this.dataToFilter.field.filter((field) => {
        let inGroup = true;
        let inCollege = true;
        let inDegree = true;
        if (currentGroupIds.length > 0) {
          if (!currentGroupIds.includes(field.id.substring(0, 2)))
            inGroup = false;
        }
        if (currentCollegeIds.length > 0) {
          if (!field.college_ids.some((x) => currentCollegeIds.includes(x)))
            inCollege = false;
        }
        if (currentDegreeIds.length > 0) {
          if (!field.degree_ids.some((x) => currentDegreeIds.includes(x)))
            inDegree = false;
        }
        return inGroup && inCollege && inDegree;
      });
      this.filteredFields =
        filteredFields.length === 0 ? this.dataToFilter.field : filteredFields;
    },
    setFilteredStudies(
      currentGroupIds,
      currentFieldIds,
      currentCollegeIds,
      currentDegreeIds
    ) {
      const filteredStudies = this.dataToFilter.studies.filter((study) => {
        let inGroup = true;
        let inField = true;
        let inCollege = true;
        let inDegree = true;
        if (currentGroupIds.length > 0) {
          if (!currentGroupIds.includes(study.group_id)) inGroup = false;
        }
        if (currentFieldIds.length > 0) {
          if (!currentFieldIds.includes(study.field_id)) inField = false;
        }
        if (currentCollegeIds.length > 0) {
          if (!study.college_ids.some((x) => currentCollegeIds.includes(x)))
            inCollege = false;
        }
        if (currentDegreeIds.length > 0) {
          if (!study.degree_ids.some((x) => currentDegreeIds.includes(x)))
            inDegree = false;
        }
        return inField && inGroup && inCollege && inDegree;
      });
      this.filteredStudies =
        filteredStudies.length === 0
          ? this.dataToFilter.studies
          : filteredStudies;
    },
    isDisabled(element) {
      if (element.id === 2) {
        // GRUPA ISCED
        if (
          this.currentFilters.isced_field.length > 0 ||
          this.currentFilters.field_id.length > 0
        ) {
          return true;
        } else {
          return false;
        }
      } else if (element.id === 3) {
        // KATEGORIA ISCED
        if (this.currentFilters.field_id.length > 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    groupNameFromField(field_id) {
      const group_id = field_id.substring(0, 2);
      const group = this.dataToFilter.group.find(
        (group) => group.id === group_id
      );
      return group.name.trim();
    },
    fieldNameFromStudies(field_id) {
      const field = this.dataToFilter.field.find(
        (field) => field.id === field_id
      );
      return field ? field.name.trim() : "";
    },
    opacityOff(id) {
      if (
        this.$refs[`autocomplete-${id}`][0].$el.getElementsByClassName(
          "v-input__prepend-inner"
        )[0].style.opacity === "1"
      ) {
        this.$refs[`autocomplete-${id}`][0].$el.getElementsByClassName(
          "v-input__prepend-inner"
        )[0].style.opacity = 0;
      }
    },
    blurSelect(e, id) {
      setTimeout(() => {
        if (!e.target.value) {
          this.$refs[`autocomplete-${id}`][0].$el.getElementsByClassName(
            "v-input__prepend-inner"
          )[0].style.opacity = 1;
        }
      }, 100);
    },
    onFocus(e, id) {
      this.$refs[`autocomplete-${id}`][0].$el.getElementsByClassName(
        "v-input__prepend-inner"
      )[0].style.opacity = 0;
    },
    changeDate(e, state) {
      if (state === "min") {
        this.$store.set(`filters/currentFilters@dates.min!`, e);
      } else {
        this.$store.set(`filters/currentFilters@dates.max!`, e);
      }
      if (
        state === "max" &&
        this.currentFilters.dates.max < this.currentFilters.dates.min
      ) {
        this.$store.set(
          `filters/currentFilters@dates.min!`,
          this.currentFilters.dates.max
        );
      }
      if (
        state === "min" &&
        this.currentFilters.dates.min > this.currentFilters.dates.max
      ) {
        this.$store.set(
          `filters/currentFilters@dates.max!`,
          this.currentFilters.dates.min
        );
      }
    },
    onAutocompleteChange(column, value) {
      this.$store.set(`filters/currentFilters@${column}!`, value);
    },
  },
  computed: {
    dataToFilter: get("filters/dataToFilter"),
    currentFilters: sync("filters/currentFilters"),
    yearsArray() {
      var arr = [];
      let min = this.dataToFilter?.years[0].min;
      while (arr[arr.length - 1] !== this.dataToFilter?.years[0].max)
        arr.push(min++);
      return arr;
    },
    filters() {
      return {
        dateRange: {
          range: [
            this.dataToFilter ? this.dataToFilter.years[0].min : 0,
            this.dataToFilter ? this.dataToFilter.years[0].max : 0,
          ],
        },
        selects: [
          {
            id: 0,
            focused: false,
            column: "gender_id",
            title: "Płeć",
            values: "plec",
            items: this.dataToFilter?.genders,
            dataToFilterName: 'genders',
          },
          {
            id: 1,
            focused: false,
            column: "college_id",
            title: "Uczelnia",
            values: [],
            items: this.filteredColleges,
            dataToFilterName: 'colleges',
          },
          {
            id: 5,
            focused: false,
            column: "degree_id",
            title: "Stopień",
            values: [],
            items: this.filteredDegrees,
            dataToFilterName: 'degrees',
          },
          {
            id: 2,
            focused: false,
            column: "isced_group",
            title: "Grupa ISCED",
            values: [],
            items: this.filteredGroups,
            dataToFilterName: 'group',
          },
          {
            id: 3,
            focused: false,
            column: "isced_field",
            title: "Kategoria ISCED",
            values: [],
            items: this.filteredFields,
            dataToFilterName: 'field',
          },
          {
            id: 4,
            focused: false,
            column: "field_id",
            title: "Kierunek studiów",
            values: [],
            items: this.filteredStudies,
            dataToFilterName: 'studies',
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.title-filter {
  font-size: 0.95rem;
  font-weight: 500;
}

.value-filter {
  font-size: 0.95rem;
}

.subheader-list-item {
  margin-top: 1px;
  font-size: 0.72rem !important;
  opacity: 0.7;
}

::v-deep .v-input {
  margin: 0.3rem 0;
}

::v-deep .v-input__prepend-inner {
  margin-top: auto !important;
  margin-bottom: auto !important;
  max-width: 500px !important;
  display: block;
  position: absolute;
  height: 100%;
  opacity: 1;
  display: flex;
  align-items: center;
  transition: opacity 0.2s linear;
}

::v-deep .v-select__slot {
  width: 100% !important;
  padding: 7px 0;
}

::v-deep .v-list-item__title {
  font-size: 0.8rem !important;
  font-weight: 500 !important;
}

::v-deep .v-list-item {
  font-size: 0.95rem !important;
}

::v-deep .custom-list-item {
  align-items: start !important;
}

::v-deep .custom-list-item .v-list-item {
  align-items: start !important;
}

.custom-list-item {
  align-items: start !important;
}

::v-deep .v-list-item__title,
::v-deep .v-list-item__subtitle {
  display: block;
  white-space: pre-wrap;
  text-overflow: initial;
  max-width: 300px;
}

::v-deep .v-list-item__action {
  margin-right: 18px !important;
  align-self: initial !important;
}

::v-deep .v-text-field__details {
  display: none !important;
}

::v-deep .v-input__append-outer,
::v-deep .v-input__prepend-outer {
  display: block !important;
}

::v-deep .v-messages {
  display: none !important;
}
</style>
