import Vue from 'vue'
import Vuex from 'vuex'
import pathify from 'vuex-pathify'

import filters from '@/store/filters.js'
import map from '@/store/map.js'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [pathify.plugin],
  modules: {
    filters,
    map
  }
})