import axios from "axios";

const instance = axios.create({
  // baseURL: `${window.origin}`,
  // baseURL: "http://127.0.0.1:5000",
  timeout: 20000,
  headers: {},
});

export default instance;
