<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script>
// import * as OLUtil from 'ol/util';

export default ({
  // mounted() {
  //   console.log(OLUtil.VERSION);
  // }
})
</script>

<style>
body,
html {
  margin: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden !important;
}

.smaller-text {
  font-size: 0.95rem;
}
</style>