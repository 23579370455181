import { Circle, Fill, Stroke, Style } from "ol/style";

export default {
  methods: {
    getDefaultPolygonStyle() {
      return new Style({
        stroke: new Stroke({
          color: "#000",
        }),
        fill: new Fill({
          color: "rgba(255,255,255, 0.4)",
        }),
        image: new Circle({
          radius: 7,
          fill: new Fill({
            color: "#fff",
          }),
          stroke: new Stroke({
            width: 3,
            color: "rgba(0,0,0, 0.4)",
          }),
        }),
      })
    },
  }
}