<template>
  <div class="containerMain" style="width: 100vw; height: 100vh">
    <div v-if="loading" class="loadingOverlay" style="font-size: 15rem">
      <div class="loadingOverlay--content">
        <grid-loader
          color="#73be46"
          class="loadingOverlay--spinner"
        ></grid-loader>
        <div class="loadingOverlay--text">Trwa ładowanie...</div>
      </div>
    </div>
    <div class="whole-sidebar">
      <div class="nav">
        <div class="titleApp">
          <img
            src="../assets/lublin-normal.svg"
            class="lublin-logo"
            alt=""
            srcset=""
          />
          <v-dialog
            v-model="dialog"
            width="500"
            class="z-index: 9999 !important"
            scrollable
            persistent
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                elevation="0"
                color="primary"
                class="info-button"
                style="margin-left: auto"
                v-bind="attrs"
                v-on="on"
                text
              >
                <v-icon>mdi-information-variant</v-icon
                ><span
                  style="
                    display: inline-flex !important;
                    text-transform: none !important;
                    margin: auto;
                  "
                  >Informacje</span
                >
              </v-btn>
            </template>

            <v-card>
              <v-card-title class="images-title">
                <!-- <v-spacer></v-spacer> -->
                <img
                  src="../assets/lublin-normal.svg"
                  style="max-height: 40px"
                  alt=""
                  srcset=""
                />
                <img
                  src="../assets/esm50.png"
                  style="max-height: 40px"
                  alt=""
                  srcset=""
                />
                <img
                  src="../assets/logo_skng50.png"
                  style="max-height: 40px"
                  alt=""
                  srcset=""
                />
                <img
                  src="../assets/logo_geoit50.png"
                  style="max-height: 40px"
                  alt=""
                  srcset=""
                />
                <img
                  src="../assets/kwaternion50.png"
                  style="max-height: 40px"
                  alt=""
                  srcset=""
                />
                <!-- <v-spacer></v-spacer> -->
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text
                style="height: 500px; color: #000; padding-top: 1rem"
              >
                Niniejsza aplikacja umożliwia wizualizację pochodzenia studentów
                przyjętych na pierwszy rok I i II stopnia studiów, jednolitych
                studiów magisterskich, studiów doktorskich oraz podyplomowych do
                lubelskich uczelni publicznych w latach akademickich 2011-2020 w
                podziale na: powiaty, województwa i państwa.
                <br />
                <br />
                Projekt został zrealizowany w ramach porozumienia o współpracy
                nr 23/WSP/21 zawartego dnia 26 kwietnia 2021 r. pomiędzy Gminą
                Lublin a Uniwersytetem Marii Curie-Skłodowskiej w Lublinie,
                Katolickim Uniwersytetem Lubelskim Jana Pawła II, Politechniką
                Lubelską, Uniwersytetem Przyrodniczym w Lublinie oraz
                Uniwersytetem Medycznym w Lublinie.
                <br /><br />
                Raport pt. "Analiza napływu studentów do Lublina", będący bazą
                danych do aplikacji internetowej, powstał przy udziale członków
                Studenckiego Koła Naukowego Geografów UMCS im. Adama Malickiego
                w Lublinie pod opieka merytoryczną dr. Piotra Demczuka, we
                współpracy z członkami Studenckiego Koła Naukowego "KWATERNION"
                z Politechniki Lubelskiej pod opieką merytoryczną dr Ewy Łazuki,
                prof. uczelni.
                <br /><br />
                Aplikacja internetowa została opracowana przez Patryka Bilskiego
                i Alicję Nieć ze Studenckiego Koła Naukowego Geoinformatyków
                "GeoIT" UMCS. Baza danych została przetworzona przy udziale
                Piotra Urbańskiego ze Studenckiego Koła Naukowego Geografów UMCS
                im. Adama Malickiego w Lublinie.
                <br /><br />
                Koordynator utworzenia aplikacji: dr Piotr Demczuk - Uniwersytet
                Marii Curie-Skłodowskiej w Lublinie. Wsparcie z ramienia Urzędu
                Miasta Lublin: Katarzyna Bujan - Wydział Strategii i
                Przedsiębiorczości.
                <br /><br />
                <span class="subDialog">
                  Źródło danych: Urbański P.M., Alinowski J., Burakowska M.,
                  Cebula J., Kruszczyńska E., Nowak K., Płachta J., Sygar S.,
                  Szalewicz A., Piłat M., Hołowiecka A., Malec M., Pacyna A.,
                  Popławska E., 2021. Analiza napływu studentów do Lublina
                </span>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions style="padding: 1rem 1rem">
                <v-checkbox
                  v-model="noShowAgain"
                  dense
                  hide-details
                  class="checkbox-dialog"
                  style="margin: 0 !important; padding: 0 !important"
                  :label="`Nie pokazuj więcej`"
                ></v-checkbox>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  class="button-dialog"
                  elevation="0"
                  @click="closeDialog"
                >
                  Zatwierdź
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-btn
            elevation="0"
            color="primary"
            @click="mobileMenuOpened = !mobileMenuOpened"
            style="margin-left: 1rem"
            class="mobile--menu-button"
            :outlined="mobileMenuOpened ? false : true"
            fab
            small
          >
            <v-icon>mdi-dots-horizontal</v-icon>
          </v-btn>
        </div>
      </div>
      <sidebar-container
        @filterCompleted="filterCompleted"
        class="sidebar"
        :style="{
          display: isMobile ? (mobileMenuOpened ? 'block' : 'none') : 'block',
        }"
      ></sidebar-container>
    </div>
    <map-container
      class="map"
      :geoms="geoms"
      :filterData="filterData"
    ></map-container>
    <v-snackbar
      v-model="globalSnackbar"
      :timeout="3000"
      color="error"
      transition="scroll-y-reverse-transition"
    >
      {{ globalSnackbarText }}
    </v-snackbar>
  </div>
</template>

<script>
import MapContainer from "@/components/MapContainer";
import SidebarContainer from "@/components/SidebarContainer";

import api from "@/services/api.js";

import GridLoader from "vue-spinner/src/GridLoader.vue";

export default {
  components: {
    MapContainer,
    SidebarContainer,
    GridLoader,
  },
  data: () => ({
    isMobile: false,
    mobileMenuOpened: false,
    noShowAgain: localStorage.getItem("showDialog") === "false" ? true : false,
    dialog: localStorage.getItem("showDialog") === "false" ? false : true,
    geoms: null,
    filterData: null,
    loading: true,
    globalSnackbar: false,
    globalSnackbarText: "Brak wyników wyszukiwania dla podanych filtrów.",
  }),
  methods: {
    closeDialog() {
      localStorage.setItem("showDialog", !this.noShowAgain);
      this.dialog = false;
    },
    triggerSnackbar(value, text = null) {
      if (text) {
        this.globalSnackbarText = text;
      } else {
        this.globalSnackbarText =
          "Brak wyników wyszukiwania dla podanych filtrów.";
      }
      this.globalSnackbar = value;
    },
    async getGeoms() {
      let getCountries = new Promise(function (resolve) {
        return resolve(import("../assets/countries.json"));
      });
      let getA02 = new Promise(function (resolve) {
        return resolve(import("../assets/a02dp.json"));
      });
      let getA01 = new Promise(function (resolve) {
        return resolve(import("../assets/a01dp.json"));
      });
      let [cn, a02, a01] = await Promise.all([getCountries, getA02, getA01]);
      return { countries: cn, a02: a02, a01: a01 };
    },
    filterCompleted(data) {
      this.filterData = data;
    },

    async initMounted() {
      var startTime = performance.now();
      this.geoms = await this.getGeoms();
      var endTime = performance.now();
      console.info(
        `Performance test - geoms loaded in ${(
          (endTime - startTime) /
          1000
        ).toFixed(2)} secs`
      );
      const query = await api.get("/api/filtersData");
      const obj = {
        field: JSON.parse(query.data.ISCED_field),
        group: JSON.parse(query.data.ISCED_group),
        studies: JSON.parse(query.data.studies_field),
        colleges: JSON.parse(query.data.studies_college),
        genders: JSON.parse(query.data.genders),
        years: JSON.parse(query.data.years),
        degrees: JSON.parse(query.data.degrees),
      };
      this.$store.set("filters/SET_DATA_TO_FILTER!", obj);
      this.$store.set(`filters/currentFilters@dates!`, {
        min: JSON.parse(query.data.years)[0].min,
        max: JSON.parse(query.data.years)[0].max,
      });
      this.loading = false;
    },
  },
  mounted() {
    this.isMobile = window.innerWidth <= 1024;
    window.addEventListener(
      "resize",
      () => {
        this.isMobile = window.innerWidth <= 1024;
      },
      { passive: true }
    );
    this.$root.$on("triggerErrorSnackbar", this.triggerSnackbar);
    this.initMounted();
  },
};
</script>

<style lang="scss">
@media screen and (max-width: 1024px) {
  .containerMain {
    display: block !important;
  }

  .whole-sidebar {
    height: auto !important;
    width: auto !important;
  }

  .sidebar {
    position: absolute !important;
    z-index: 150 !important;
    margin-top: 80px !important;
    width: 100% !important;
    height: calc(100% - 80px) !important;
  }

  .map {
    height: calc(100% - 80px) !important;
  }

  .mobile--menu-button {
    display: block !important;
  }

  .nav {
    width: 100% !important;
  }
}

.mobile--menu-button {
  display: none;
}

.info-button .v-btn__content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.containerMain {
  position: relative;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 450px;
  height: 100vh;
  // width: 100vw;
  grid-template-areas:
    "map wholesidebar"
    "map wholesidebar";
}

.lublin-logo {
  height: 70%;
}

.whole-sidebar {
  grid-area: wholesidebar;
  height: 100vh;
  display: grid;
  grid-template-rows: 80px 1fr;
  grid-template-areas:
    "nav"
    "sidebar";
}

.nav {
  grid-area: nav;
  z-index: 2;
  height: 100%;
  width: 450px;
  background-color: #f4f4f4;
  filter: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07))
    drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
}

.titleApp {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

.titleApp span {
  display: block;
}

.sidebar {
  grid-area: sidebar;
  z-index: 2;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  background-color: #fff;
  filter: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07))
    drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
}

.map {
  grid-area: map;
}

.images-title {
  display: flex;
  justify-content: center;
  gap: 10px 14px;
}

.loadingOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  z-index: 9999;
  align-items: center;
  justify-content: center;
}

.loadingOverlay--content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loadingOverlay--spinner {
  margin-right: 5px;
  margin-bottom: 15px;
}

.loadingOverlay--text {
  font-size: 0.8rem;
}

.v-snack {
  bottom: 1rem !important;
}

.v-sheet {
  filter: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07))
    drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
  box-shadow: none !important;
}

.v-alert {
  filter: none !important;
}

.subDialog {
  font-size: 0.8rem;
  color: #5f5f5f;
}

.checkbox-dialog .v-label {
  font-size: 0.9rem !important;
}

.button-dialog {
  font-size: 0.8rem !important;
  padding: 0.5rem 1rem !important;
  text-transform: none;
  font-weight: 400;
}
</style>
