import { make } from "vuex-pathify";

const state = {
  mapTab: 0,
  overlayCoords: undefined,
};

const mutations = {
  ...make.mutations(state),
};

export default {
  namespaced: true,
  state,
  mutations,
};
