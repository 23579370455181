import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import pl from "vuetify/lib/locale/pl";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#73be46", // lbn
        // primary: '#1DA57A',
        secondary: "#ff001e",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
      },
    },
  },
  lang: {
    locales: { pl },
    current: "pl",
  },
  breakpoint: {
    thresholds: {
      xs: 450,
      sm: 640,
      md: 1024,
      lg: 1280,
    },
  },
});
