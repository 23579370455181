<template>
  <div>
    <div ref="map" class="map-container">
      <div class="top-controls">
        <v-card width="55" class="pa-1 elevation-0 card-switch">
          <v-btn
            text
            block
            class="pa-1 arrowButton"
            :disabled="getDisabledUp"
            style="width: auto; min-width: 0"
            @click="
              map
                .getView()
                .animate({ zoom: map.getView().getZoom() + 1, duration: 250 })
            "
          >
            <template>
              <svg
                class="w-6 h-6"
                fill="none"
                style="width: 0.95rem"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                ></path>
              </svg>
            </template>
          </v-btn>
          <v-btn
            text
            block
            class="pa-1 arrowButton"
            style="width: auto; min-width: 0"
            :disabled="getDisabledDown"
            @click="
              map
                .getView()
                .animate({ zoom: map.getView().getZoom() - 1, duration: 250 })
            "
          >
            <template>
              <svg
                class="w-6 h-6"
                fill="none"
                style="width: 0.7rem"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M20 12H4"
                ></path>
              </svg>
            </template>
          </v-btn>
        </v-card>
        <v-btn-toggle
          :value="selectedGeom"
          class="select-geom-group"
          mandatory
          @change="changeGeom"
        >
          <v-btn
            :color="selectedGeom === 0 ? 'primary' : 'white'"
            :style="`color: ${selectedGeom === 0 ? '#fff' : ''}`"
          >
            <span>Powiaty</span>
          </v-btn>
          <v-btn
            :color="selectedGeom === 1 ? 'primary' : 'white'"
            :style="`color: ${selectedGeom === 1 ? '#fff' : ''}`"
          >
            <span>Województwa</span>
          </v-btn>
          <v-btn
            :color="selectedGeom === 2 ? 'primary' : 'white'"
            :style="`color: ${selectedGeom === 2 ? '#fff' : ''}`"
          >
            <span>Państwa</span>
          </v-btn>
        </v-btn-toggle>
      </div>
    </div>
    <div id="popup" class="ol-popup">
      <a href="#" id="popup-closer" class="ol-popup-closer"></a>
      <div id="popup-content"></div>
    </div>
  </div>
</template>

<script>
import View from "ol/View";
import Map from "ol/Map";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import GeoJSON from "ol/format/GeoJSON";
import { Fill, Stroke, Style } from "ol/style";
import VectorImageLayer from "ol/layer/VectorImage";
import VectorSource from "ol/source/Vector";
import Overlay from "ol/Overlay";
import { ScaleLine, defaults as defaultControls } from "ol/control";

import { VBtnToggle } from "vuetify/lib";

import geostats from "geostats/lib/geostats.min.js";
import rainbow from "rainbowvis.js";
// import Stamen from 'ol/source/Stamen';

import layerManipulations from "@/mixins/map/layerManipulations.js";
import layerStyling from "@/mixins/map/layerStyling.js";
import { sync } from "vuex-pathify";
import Feature from "ol/Feature";
export default {
  data: () => ({
    map: undefined,
    dictGeoms: ["a02", "a01", "countries"],
  }),
  components: {
    VBtnToggle,
  },
  mixins: [layerManipulations, layerStyling],
  props: {
    geoms: {
      default: null,
    },
    filterData: {
      default: null,
    },
  },
  computed: {
    selectedGeom: sync("map/mapTab"),
    overlayCoords: sync("map/overlayCoords"),
    currentSymbolization: sync("filters/currentSymbolization"),
    symbolizationMethods: sync("filters/symbolizationMethods"),
    getDisabledUp() {
      if (this.map) {
        return (
          this.map?.getView().getZoom() >= this.map?.getView().getMaxZoom()
        );
      } else {
        return true;
      }
    },
    getDisabledDown() {
      if (this.map) {
        return (
          this.map?.getView().getZoom() <= this.map?.getView().getMinZoom()
        );
      } else {
        return true;
      }
    },
  },
  watch: {
    selectedGeom: function () {
      this.overlayCoords = undefined;
      this.getLayerById("selected_layer").getSource().clear();
    },
    overlayCoords: function (newPosition) {
      this.map.getOverlayById("main-popup").setPosition(newPosition);
    },
    geoms: function (newGeoms) {
      this.getLayerById("geom_layer")
        .getSource()
        .addFeatures(
          new GeoJSON().readFeatures(newGeoms.a02, {
            featureProjection: "EPSG:3857",
          })
        );
      this.map
        .getView()
        .fit(this.getLayerById("geom_layer").getSource().getExtent());
    },
    filterData: function (newFilters) {
      if (newFilters) {
        this.generateSymbology({
          newFilters:
            this.selectedGeom === 0
              ? JSON.parse(newFilters.A02Data)
              : this.selectedGeom === 1
              ? JSON.parse(newFilters.A01Data)
              : JSON.parse(newFilters.CountriesData),
          symbolMethod: this.currentSymbolization,
        });
        this.$root.$on("generateSymbology", this.generateSymbology);
      } else {
        for (let feature of this.getLayerById("geom_layer")
          .getSource()
          .getFeatures()) {
          feature.setStyle(this.getDefaultPolygonStyle());
        }
      }
    },
  },
  methods: {
    unique(value, index, self) {
      return self.indexOf(value) === index;
    },
    generateSymbology({
      newFilters = this.selectedGeom === 0
        ? JSON.parse(this.filterData.A02Data)
        : this.selectedGeom === 1
        ? JSON.parse(this.filterData.A01Data)
        : JSON.parse(this.filterData.CountriesData),
      symbolMethod,
    }) {
      let result = [];
      let arr = newFilters.map((x) => x.count);
      arr = arr.filter((value) => value !== 0);
      let newOb = new geostats(arr);
      let numberOfItems = 5;
      let classification;
      for (let method of this.symbolizationMethods) {
        let classTry;
        try {
          classTry = newOb[method.value](numberOfItems);
          this.$store.set("filters/SET_UPDATED_DISABLE_SYMBOL!", {
            method: method.value,
            value: false,
          });
        } catch {
          if (method.value === symbolMethod) {
            this.currentSymbolization = this.symbolizationMethods.find(
              (method) => method.disabled === false
            ).value;
            return;
          }
          this.$store.set("filters/SET_UPDATED_DISABLE_SYMBOL!", {
            method: method.value,
            value: true,
          });
        }
        if (method.value === symbolMethod) {
          classification = classTry;
        }
      }
      const setClassification = [...new Set(classification)];
      if (setClassification.length !== classification.length) {
        classification = setClassification;
      }
      let rain = new rainbow();
      rain.setNumberRange(1, classification.length + 1);
      rain.setSpectrum("#ffffff", "#73BE46", "#2c4a1b");
      result.push({
        color: `#ffffff`,
        interval: [0, 0],
      });
      if (classification.length === 1) {
        const hexColourOne = rain.colourAt(2);
        result.push({
          color: `#${hexColourOne}`,
          interval: [classification[0], classification[0]],
        });
      } else {
        for (var i = 1; i <= classification.length - 1; i++) {
          var hexColour = rain.colourAt(i + 1);
          let currentValue =
            classification[i] % 1 === 0
              ? classification[i]
              : parseFloat(classification[i].toFixed(0));
          let behindValue =
            classification[i - 1] % 1 === 0
              ? classification[i - 1]
              : parseFloat(classification[i - 1].toFixed(0));
          let nextValue =
            behindValue % 1 === 0
              ? behindValue + 1
              : parseFloat((behindValue + 0.01).toFixed(0));
          result.push({
            color: `#${hexColour}`,
            interval: i === 1 ? [1, currentValue] : [nextValue, currentValue],
          });
        }
      }
      this.$store.set("filters/SET_STEPS!", result);
      for (let feature of this.getLayerById("geom_layer")
        .getSource()
        .getFeatures()) {
        const kod = feature.get(
          this.selectedGeom === 0 || this.selectedGeom === 1
            ? "JPT_KOD_JE"
            : "ADM0_A3"
        );
        const valueObj = newFilters.find(
          (x) =>
            x[
              this.selectedGeom === 0 || this.selectedGeom === 1
                ? "jpt_kod_je"
                : "adm0_a3"
            ] === kod
        );
        feature.set("value", valueObj.count);
        feature.set(
          "nazwa_pl",
          valueObj[
            this.selectedGeom === 0 || this.selectedGeom === 1
              ? "jpt_nazwa_"
              : "name_pl"
          ]
        );
        let findedInterval = result.find(
          (x) =>
            feature.get("value") >= x.interval[0] &&
            feature.get("value") <= x.interval[1]
        );
        var c = findedInterval.color.substring(1); // strip #
        var rgb = parseInt(c, 16); // convert rrggbb to decimal
        var r = (rgb >> 16) & 0xff; // extract red
        var g = (rgb >> 8) & 0xff; // extract green
        var b = (rgb >> 0) & 0xff; // extract blue

        var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

        feature.setStyle(
          new Style({
            fill: new Fill({
              color: `${findedInterval.color}`,
            }),
            stroke: new Stroke({
              color: luma < 180 ? "#d9d9d9" : "#696969",
              width: 0.5,
            }),
          })
        );
      }
    },
    init() {
      let geomSource = new VectorSource({
        features: [],
      });
      let geomLayer = new VectorImageLayer({
        imageRatio: 3,
        source: geomSource,
        style: this.getDefaultPolygonStyle(),
      });
      geomLayer.set("layer_id", "geom_layer");

      let hoveredGeom = new VectorSource({
        features: [],
      });
      const hoveredStyle = new Style({
        fill: new Fill({
          color: "rgba(255,255,255,0.2)",
        }),
      });
      let hoveredLayer = new VectorImageLayer({
        imageRatio: 3,
        source: hoveredGeom,
        style: hoveredStyle,
      });
      hoveredLayer.setZIndex(9999);
      hoveredLayer.set("layer_id", "hovered_layer");

      let selectedGeom = new VectorSource({
        features: [],
      });
      let selectedLayer = new VectorImageLayer({
        imageRatio: 3,
        source: selectedGeom,
        style: new Style({
          stroke: new Stroke({
            color: "yellow",
            width: 3,
          }),
        }),
      });
      selectedLayer.set("layer_id", "selected_layer");

      const container = document.getElementById("popup");
      const content = document.getElementById("popup-content");
      const closer = document.getElementById("popup-closer");

      const overlay = new Overlay({
        element: container,
        id: "main-popup",
        autoPan: {
          animation: {
            duration: 250,
          },
        },
      });
      const self = this;
      closer.onclick = function () {
        self.overlayCoords = undefined;
        selectedGeom.clear();
        closer.blur();
        return false;
      };
      const control = new ScaleLine({
        units: "metric",
      });
      this.map = new Map({
        target: this.$refs["map"],
        pixelRatio: 1.0,
        controls: defaultControls().extend([control]),
        layers: [
          new TileLayer({
            source: new OSM(),
          }),
          geomLayer,
          hoveredLayer,
          selectedLayer,
        ],
        overlays: [overlay],
        view: new View({
          zoom: 0,
          center: [0, 0],
          maxZoom: 11,
          minZoom: 3,
          constrainResolution: true,
        }),
      });
      let hoveredFeature;
      this.$nextTick(() => {
        this.$refs.map.addEventListener("mouseout", () => {
          this.getLayerById("hovered_layer").getSource().clear();
          hoveredFeature = undefined;
        });
      });
      this.map.on("pointermove", (evt) => {
        if (this.filterData) {
          if (!evt.dragging) {
            geomLayer.getFeatures(evt.pixel).then((features) => {
              if (features.length !== 0) {
                this.$refs.map.style.cursor = "pointer";
                if (hoveredFeature !== features[0]) {
                  hoveredGeom.clear();
                  const newF = new Feature({
                    geometry: features[0].getGeometry(),
                  });
                  hoveredGeom.addFeature(newF);
                }
                hoveredFeature = features[0];
              } else {
                hoveredGeom.clear();
                hoveredFeature = undefined;
                this.$refs.map.style.cursor = "default";
              }
            });
          }
        }
      });
      let selectedFeature;
      this.map.on("click", (evt) => {
        if (this.filterData) {
          geomLayer.getFeatures(evt.pixel).then((features) => {
            if (features.length !== 0) {
              if (selectedFeature !== features[0]) {
                selectedGeom.clear();
                const newF = new Feature({
                  geometry: features[0].getGeometry(),
                });
                selectedGeom.addFeature(newF);
              }
              selectedFeature = features[0];
              content.innerHTML = `<div class="stat-title">${
                features[0].get("nazwa_pl") || features[0].get("JPT_NAZWA_")
              }</div>`;
              if (features[0].get("value") || features[0].get("value") === 0) {
                content.innerHTML += `<div class="stat-number">${features[0].get(
                  "value"
                )}</div>`;
              }
              this.overlayCoords = evt.coordinate;
            } else {
              selectedGeom.clear();
              this.overlayCoords = undefined;
            }
          });
        }
      });
    },
    changeGeom(value) {
      let fitBound = false;
      let getHigher = false;
      if (this.selectedGeom === 2) fitBound = true;
      if (value === 2) getHigher = true;
      this.selectedGeom = value;
      this.getLayerById("geom_layer").getSource().clear();
      this.getLayerById("geom_layer")
        .getSource()
        .addFeatures(
          new GeoJSON().readFeatures(this.geoms[this.dictGeoms[value]], {
            featureProjection: "EPSG:3857",
          })
        );
      if (fitBound) {
        this.map
          .getView()
          .fit(this.getLayerById("geom_layer").getSource().getExtent());
      }
      if (getHigher) {
        this.map.getView().setZoom(4);
      }
      if (this.filterData) {
        this.generateSymbology({
          newFilters:
            this.selectedGeom === 0
              ? JSON.parse(this.filterData.A02Data)
              : this.selectedGeom === 1
              ? JSON.parse(this.filterData.A01Data)
              : JSON.parse(this.filterData.CountriesData),
          symbolMethod: this.currentSymbolization,
        });
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 1024px) {
  .map-container {
    height: 100% !important;
  }
}

@media (max-width: 450px) {
  .card-switch {
    top: 5rem !important;
  }

  .select-geom-group {
    width: auto !important;
    left: 1rem !important;
  }

  .select-geom-group .v-btn {
    flex: 2 1 auto !important;
    width: auto !important;
  }
}

.map-container {
  width: 100%;
  height: 100vh;
  position: relative;
}

.select-geom-group {
  border-radius: 50px;
  position: absolute;
  z-index: 99;
  right: 1rem;
  top: 1rem;
  filter: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07))
    drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
}

.select-geom-group .v-btn {
  height: 40px !important;
  margin: 3px;
  border-radius: 50px !important;
  letter-spacing: 0px;
  font-size: 0.9rem;
}

.card-switch {
  position: absolute;
  left: 1rem;
  top: 1rem;
  z-index: 99;
  width: 35px !important;
  border-radius: 50px;
  filter: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07))
    drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
}

.arrowButton {
  height: 27px !important;
  border-radius: 50px;
}

::v-deep .v-btn-toggle > .v-btn.v-btn {
  border: 0 !important;
}

::v-deep .ol-control {
  display: none;
}

.arrowButton:hover .arrow {
  opacity: 0.8 !important;
  transition: 0.2s linear;
}

::v-deep .v-btn {
  text-transform: initial !important;
}

::v-deep .v-btn:before {
  background-color: var(--v-primary-base) !important;
}

::v-deep .ol-scale-line {
  bottom: 1rem;
  left: 1rem;
  background-color: rgba(255, 255, 255, 0.65) !important;
}

::v-deep .ol-scale-line-inner {
  border-color: rgba(0, 0, 0, 1);
  font-size: 0.8rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
}

::v-deep .stat-title {
  font-size: 0.9rem;
  opacity: 0.8;
}

::v-deep .stat-number {
  font-size: 1.1rem;
  font-weight: 500;
}

::v-deep .v-snack {
  bottom: 1.5rem !important;
}

.ol-popup {
  position: absolute;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 10px;
  bottom: 12px;
  left: -100px;
  min-width: 200px;
}

.ol-popup:after,
.ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 100px;
  margin-left: -10px;
}

.ol-popup:before {
  border-width: 11px;
  left: 100px;
  margin-left: -11px;
}

.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 8px;
}

.ol-popup-closer:after {
  content: "✖";
}
</style>
