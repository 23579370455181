<template>
  <div class="sidebar-content">
    <v-alert
      dense
      text
      type="info"
      dismissible
      transition="fade-transition"
      style="font-size: 0.8rem"
    >
      <template v-slot:close="{ toggle }">
        <v-btn
          class="mx-2 elevation-0"
          x-small
          icon
          @click="toggle"
          color="blue"
          style="margin-right: 0 !important"
        >
          <v-icon dark> mdi-close </v-icon>
        </v-btn>
      </template>
      Aby wyświetlić wszystkie dostępne dane należy kliknąć
      <strong>Filtruj</strong> bez wybierania żadnych kryteriów.
    </v-alert>
    <filters-sidebar-container
      :is-loading="isLoading"
    ></filters-sidebar-container>
    <v-btn
      block
      color="primary elevation-0"
      style="margin-top: 1rem"
      class="filter-button"
      @click="filterHandle"
      :loading="isLoading"
      >Filtruj</v-btn
    >
    <filters-legend
      v-if="filtersFetched"
      :is-loading="isLoading"
    ></filters-legend>
  </div>
</template>

<script>
import { call, sync, get } from "vuex-pathify";
import FiltersLegend from "@/components/FiltersLegend";
import FiltersSidebarContainer from "@/components/FiltersSidebarContainer";

export default {
  components: {
    FiltersLegend,
    FiltersSidebarContainer,
  },
  data: () => ({
    isLoading: false,
  }),
  computed: {
    overlayCoords: sync("map/overlayCoords"),
    currentFilters: sync("filters/currentFilters"),
    filtersFetched: get("filters/filtersFetched"),
  },
  methods: {
    filter: call("filters/filter"),
    async filterHandle() {
      this.overlayCoords = undefined;
      this.isLoading = true;
      try {
        const query = await this.filter({
          start_years: this.currentFilters.dates,
          college_id: {
            values: Object.values(this.currentFilters.college_id).map(
              (value) => value.id
            ),
            column: `s."college_id"`,
          },
          degree_id: {
            values: Object.values(this.currentFilters.degree_id).map(
              (value) => value.id
            ),
            column: `s."degree_id"`,
          },
          field_id: {
            values: Object.values(this.currentFilters.field_id).map(
              (value) => value.id
            ),
            column: `s."field_id"`,
          },
          gender_id: {
            values: Object.values(this.currentFilters.gender_id).map(
              (value) => value.id
            ),
            column: `s."gender_id"`,
          },
          isced_group: {
            values: Object.values(this.currentFilters.isced_group).map(
              (value) => value.id
            ),
            column: `sf."group_id"`,
          },
          isced_field: {
            values: Object.values(this.currentFilters.isced_field).map(
              (value) => value.id
            ),
            column: `sf."field_id"`,
          },
        });
        if (query.status === 200) {
          const voivodeshipsData = JSON.parse(query.data.A01Data);
          if (voivodeshipsData.find((data) => data.count !== 0)) {
            this.$emit("filterCompleted", query.data);
            this.$store.set("filters/SET_FILTERS_FETCHED!", true);
          } else {
            this.$emit("filterCompleted", null);
            this.$store.set("filters/SET_FILTERS_FETCHED!", false);
            this.$root.$emit("triggerErrorSnackbar", true);
          }
        }
      } catch {
        this.$root.$emit(
          "triggerErrorSnackbar",
          true,
          "Wystąpił problem. Spróbuj ponownie."
        );
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar-content {
  padding: 1.5rem 1.5rem;
}

.filter-button {
  font-size: 0.8rem;
}

::v-deep {
  .v-alert__icon {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}
</style>
