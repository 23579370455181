<template>
  <div class="legend-container-sidebar">
    <div style="width: 100%">
      <span class="symbology-header">Metoda prezentacji danych <span style="opacity: 0.7">[liczba studentów przyjętych na I rok studiów]</span></span>
      <v-select
        :items="symbolizationMethods"
        full-width
        v-model="currentSymbolization"
        dense
        :disabled="isLoading"
        style="font-size: 0.95rem"
        label="Symbolizacja"
      ></v-select>
    </div>
    <div style="margin-top: 2rem" class="legend-cont">
      <div v-for="step in steps" :key="step.color" class="entry-legend">
        <div
          :style="`height: 20px; width:20px; background-color: ${step.color}; border-radius: 4px; border: 1px solid #cecece`"
        ></div>
        <span
          v-if="step.interval[0] === 0 && step.interval[1] === 0"
          style="margin-left: 1rem; font-size: 0.75rem"
        >
          0
        </span>
        <span v-else style="margin-left: 1rem; font-size: 0.75rem">
          {{ step.interval[0] }} - {{ step.interval[1] }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { get, sync } from "vuex-pathify";
import { VSelect } from "vuetify/lib";
export default {
  components: {
    VSelect,
  },
  props: {
    isLoading: {
      type: Boolean,
    },
  },
  computed: {
    steps: get("filters/steps"),
    symbolizationMethods: get("filters/symbolizationMethods"),
    currentSymbolization: sync("filters/currentSymbolization"),
  },
  watch: {
    currentSymbolization: {
      handler(newValue) {
        this.$root.$emit("generateSymbology", { symbolMethod: newValue });
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.legend-cont {
  display: flex;
  flex-direction: column;
  height: 100px;
  gap: 10px;
  flex-wrap: wrap;
}

.legend-container-sidebar {
  background-color: #f5f5f5;
  padding: 1.5rem;
  margin: 2rem 0;
  border-radius: $border-radius-root;
}

.symbology-header {
  font-size: 0.8rem;
}

::v-deep .entry-legend {
  display: flex;
}

::v-deep .entry-legend:first-of-type {
  margin-top: 0;
}
</style>
